import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { JobsListBlock } from 'stile-shared/src/components/2020/blocks/JobsListBlock';
import { MediaBlock } from 'stile-shared/src/components/2020/blocks/MediaBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { P } from 'stile-shared/src/components/2020/common/Primitives';
import { PageContextType } from 'stile-shared/src/types/data';

import { getPageData } from 'utils/getPageData';

import { PageLayout } from 'templates/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/Head';

function JoinTheTeamPage(props: PageProps<object, PageContextType>) {
  const { hero } = getPageData(props.location.pathname);

  const knownJobIds = Object.keys(props.pageContext.jobMeta);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        media={
          <MediaBlock as="div" layout="step-repeat-five-left">
            <StaticImage
              loading="eager"
              src="../../../../stile-shared/assets/images/join-the-team/comp_join_team_01.jpg"
              alt="A support staff member wearing a call headset and smiling"
            />
            <StaticImage
              loading="eager"
              src="../../../../stile-shared/assets/images/join-the-team/comp_join_team_02.jpg"
              alt="The mural in Stile HQ's foyer. It is full of cartoon-style artwork in black and white, with green and pink accents"
            />
            <StaticImage
              loading="eager"
              src="../../../../stile-shared/assets/images/join-the-team/comp_join_team_03.jpg"
              alt="An engineer does focused work in a booth"
            />
            <StaticImage
              loading="eager"
              src="../../../../stile-shared/assets/images/join-the-team/comp_join_team_04.jpg"
              alt="A software engineer at a workstation with a laptop and a wide external monitor"
            />
            <StaticImage
              loading="eager"
              src="../../../../stile-shared/assets/images/join-the-team/comp_join_team_05.jpg"
              alt="A closeup of someone's desk. Headphones and a cupcake-shaped squeeze toy are in focus"
            />
          </MediaBlock>
        }
      />

      <TextBlock title="Life at Stile">
        <P>
          Stile is run by the people who started and own the company. We’re here to build something
          real and enduring. A company that in years to come we look back on and are proud of what
          we achieved, and how we achieved it.
        </P>

        <P>
          Stile is a place for people who want to have a bigger impact. For people who believe there
          is room to improve our education system. It’s for people who care deeply about quality.
          It’s for people who prefer the outsized impact you can have, and the quick decisions that
          can be made, in a smaller company. It’s for people who value the team over the player.
          It’s a place for people who love working with great people while having fun doing it.
        </P>

        <P>
          <Link url="/who-we-are/life-at-stile/">Find out more about life at Stile</Link>.
        </P>
      </TextBlock>

      <TeaserBlock
        variant="dark"
        contentMax="450px"
        title="Meet the Stile team"
        text="We asked the team how they would describe a Stile person. Here is what they had to say."
        video={{
          url: 'https://www.youtube.com/embed/tehcTUqr9t4',
          text: 'Watch (3 mins)',
          coverImage: (
            <StaticImage
              src="../../../../stile-shared/assets/images/our-team/culture_video_cover.jpg"
              alt="Several Stile staff members sitting in a relaxed meeting area. One is explaining something and the others are listening attentively"
            />
          ),
        }}
      />

      <TextBlock title="We need your help">
        <P>Interested in joining the team? We’d love to hear from you.</P>

        <P>
          Before you apply, make sure you read our{' '}
          <Link url="/who-we-are/effective-stilists/">Team Values</Link> of highly effective
          Stilists. You may also want to take a look at our{' '}
          <Link url="/who-we-are/hiring-process/">Hiring Process</Link> for joining the team.
        </P>

        <P>
          If you’re a software engineer and have an interest in what we do, or the technical
          problems we’re solving, be sure to check out our{' '}
          <Link url="/who-we-are/engineering-at-stile/">Engineering at Stile</Link> page too.
        </P>

        <P>
          If you don’t see yourself on the list below, consider{' '}
          <Link url="/who-we-are/contact/">sending us an email</Link> anyway. Maybe you’ll blow our
          socks off and we’ll make a position for you. What’s the worst that could happen?
        </P>
      </TextBlock>

      <JobsListBlock knownJobIds={knownJobIds} />

      <NextPageBlock path="/who-we-are/gender-diversity-in-stem/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default JoinTheTeamPage;
